import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/Users/mswsn/projects/vcs-childhood-elearning-q2-2020/src/components/layout.tsx";
import { Navigation } from "../components/navigation";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Hur blir jag en viktig vuxen?`}</h1>
    <p>{`Utöver att stötta Childhood genom försäljning av våra bilar, ger vi alla våra medarbetare möjligheten att bli viktiga vuxna. Det här är självklart något som är helt frivilligt, men det som återstår av det här utbildningsmaterialet handlar om just det: hur agerar jag som en viktig vuxen?`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAMBAgQF/8QAFwEAAwEAAAAAAAAAAAAAAAAAAAIDBP/aAAwDAQACEAMQAAAB31Zz1IHk9H//xAAZEAADAQEBAAAAAAAAAAAAAAABAgMRIRL/2gAIAQEAAQUCi3JO3ugwtgSLZVh3/8QAFxEBAQEBAAAAAAAAAAAAAAAAAREAUf/aAAgBAwEBPwGlmA7v/8QAFxEBAQEBAAAAAAAAAAAAAAAAAQARIf/aAAgBAgEBPwEHLt//xAAbEAACAgMBAAAAAAAAAAAAAAAAAQIREEFRYf/aAAgBAQAGPwIlGUr2VxDpIXuP/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARIWExcf/aAAgBAQABPyEBxumlOQT5MgpM/I4/djcZvc//2gAMAwEAAgADAAAAECzP/8QAGBEBAQADAAAAAAAAAAAAAAAAAQARYaH/2gAIAQMBAT8QdwyzLxf/xAAYEQEBAAMAAAAAAAAAAAAAAAABABFBUf/aAAgBAgEBPxBSTVni/8QAHBABAAICAwEAAAAAAAAAAAAAAREhALExQVGR/9oACAEBAAE/EFbuUPmTosrEdhNZNoo5DeP1CJPTGLRborIJrzFSN3xO3P/Z')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Flicka sitter ensam",
          "title": "Flicka sitter ensam",
          "src": "/static/af37d0d3b9246c26307dab95a7b3256e/e5166/lonely-girl.jpg",
          "srcSet": ["/static/af37d0d3b9246c26307dab95a7b3256e/f93b5/lonely-girl.jpg 300w", "/static/af37d0d3b9246c26307dab95a7b3256e/b4294/lonely-girl.jpg 600w", "/static/af37d0d3b9246c26307dab95a7b3256e/e5166/lonely-girl.jpg 1200w", "/static/af37d0d3b9246c26307dab95a7b3256e/b17f8/lonely-girl.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy"
        }}></img>{`
    `}</span></p>
    <h3>{`Agerande i olika situationer`}</h3>
    <p>{`För att lättare förstå hur du agerar som en viktig vuxen, ska vi titta närmare på några situationer som du kan hamna i. Kopplat till varje situation kommer du få tips och råd gällande hur du bör agera. Vi kommer därefter att avsluta med en frågedel där du har möjlighet att visa att du vet hur du agerar som en viktig vuxen.`}</p>
    <h4>{`1. Du misstänker att ett barn utsatts för sexuella övergrepp.`}</h4>
    <p>{`I det här läget finns det två saker du bör göra som viktig vuxen:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Ring till socialtjänsten i din kommun.`}</strong>{` Prata med dem och berätta vad du oroar dig för. Om du vill kan du vara anonym, både vad det gäller din och barnens identitet. Socialtjänsten finns där för att skydda barnen och hjälpa familjer som har det svårt.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Prata med barnet och fråga hur hen mår!`}</strong>{` Försök inte forcera fram förtroenden men visa att du bryr dig och finns där. Barn ger oftast många signaler innan de vågar prata – visa att du är öppen för det barnet kan vilja prata om.`}</p>
      </li>
    </ul>
    <h4>{`2. Du upptäcker ett pågående övergrepp eller ser att ett barn är i akut fara.`}</h4>
    <p>{`Om du är med om det här ska du ringa 112, omedelbart.`}</p>
    <h4>{`3. Ett barn berättar för dig om övergrepp.`}</h4>
    <p>{`Du som vuxen ska ta hand om informationen du får och `}<strong parentName="p">{`lyssna`}</strong>{`, `}<strong parentName="p">{`agera`}</strong>{` och, framför allt, `}<strong parentName="p">{`finnas kvar.`}</strong>{` Tänk på att det inte finns något `}<em parentName="p">{`normalt`}</em>{` sätt man `}<em parentName="p">{`ska`}</em>{` reagera på när man utsatts för övergrepp.`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Visa att du klarar av att ta emot berättelsen.`}</strong>{` Tänk på att barnet behöver dig nu – behåll dina egna känslor och reaktioner för dig själv, men bekräfta att du hör och tror på barnets berättelse. I intervjustudier med barn, som utsatts för övergrepp, har en del berättat att de inte anförtrott sig till någon vuxen eftersom de kände att den vuxne inte skulle kunna hantera det. Ofta testar barnet med att berätta något som inte är så grovt för att se vad reaktionen blir, om hen kan berätta mer.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Ställ öppna frågor.`}</strong>{` Om barnet berättar om något det utsatts för – lyssna, ställ öppna frågor och försök förstå vad som hänt. Öppna frågor är sådana som det inte går att svara ja eller nej på. De kan exempelvis inledas med ”berätta”, ”vad hände sen”, ”hur gick det till”...`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Avbryt inte barnet utan lyssna till punkt`}</strong>{`, men inled inte något eget förhör.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Undvik ”varför-frågor”`}</strong>{` som kan ge barnet skuldkänslor. Säg alltså inte ”Varför gick du dit?”, ”Varför drack du?”, ”Varför har du inte berättat?” eller ”Varför sa du inte nej?”.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li"><strong parentName="p">{`Lova INTE att inte berätta.`}</strong>{` Förklara för barnet att det är viktigt att du anmäler till myndigheterna för att övergreppen ska ta slut och hen få hjälp. Ta kontakt med socialtjänst eller polis för att se till att barnet och familjen ska få det stöd de behöver. Om det handlar om ett äldre barn kan du fråga om hen vill vara med när du kontaktar myndigheterna, så att barnet har insyn i och känner sig delaktig i vad som sker.`}</p>
      </li>
    </ul>
    <Navigation previous={{
      chapter: "Kapitel 2",
      title: "Vardagen för barn idag",
      link: "kapitel-2"
    }} next={{
      chapter: "Kapitel 4",
      title: "Kunskapstest",
      link: "kapitel-4"
    }} mdxType="Navigation" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      